.official {
	position: relative;
}

.official-navbar {
	z-index: 9;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 70px;
	background-color: rgba(255, 255, 255, 0);
}

.official-navbar-logo {
	position: absolute;
	left: 18.75%;
	top: 22px;
	width: 78px;
	height: 26px;
}

.official-navbar-tabbar {
	position: absolute;
	right: 18.75%;
	top: 20px;
	>a {
		display: inline-block;
		min-width: 30px;
		margin-left: 50px;
		color: #fff;
		cursor: pointer;
	}
	>a:first-child {
		margin-left: 0;
	}
	.tabbar-slide {
		position: absolute;
		left: 0;
		bottom: -10px;
		width: 30px;
		height: 2px;
		transition: transform .2s;
	}

	.tabbar-slide-up {
		background-color: #fff;
	}

	.tabbar-slide-next {
		background-color: #FF003C;
	}
	.tabItem-active {
		color: #FF003C;
	}
	
	.tabItem {
		color: #333;
	}
	
	.tabItem-top-up {
		color: #fff;
	}
}

.official-navbar-next {
	background-color: #fff;
}

.official-header {
	position: relative;
	height: 600px;
	background-color: #7CBAC7;
	background: url(../../../assets/sys/official/banner.png);
	background-repeat: no-repeat;
	background-size: contain;
	background-size: 100% 100%;
	.official-header-title {
		z-index: 2;
		position: absolute;
		top: 223px;
		left: 0;
		right: 0;
		text-align: center;
		font-size: 60px;
		font-weight: 100;
		color: #fff;
	}
	.official-header-subTitle {
		z-index: 2;
		position: absolute;
		top: 321px;
		left: 0;
		right: 0;
		text-align: center;
		font-size: 36px;
		font-weight: 100;
		color: #fff;
	}
	.official-header-mask {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}
}

.official-about {
	height: 518px;
	position: relative;
	background-color: #fff;
	.watermark {
		padding-top: 62px;
		font-size: 70px;
		top: 0;
		font-weight: bold;
		text-align: center;
		color: #F5F5F5;
	}
	.title {
		position: absolute;
		top: 109px;
		left: 0;
		right: 0;
		font-size: 40px;
		text-align: center;
		color: #333;
	}
	.content {
		position: absolute;
		left: 0;
		right: 0;
		top: 195px;
		text-align: center;
		color: #666;
		>div {
			font-size: 16px;
			line-height: 36px;
		}
	}
	.bottom {
		position: absolute;
		top: 353px;
		width: 835px;
		overflow-y: hidden;
		left: 0;
		right: 0;
		margin: 0 auto;
		.bottom-item {
			float: left;
			width: 112px;
			margin-right: 129px;
			.bottom-item-top {
				>span:nth-child(1) {
					font-size: 36px;
					font-family: Bebas;
				}
				>span:nth-child(2) {
					font-size: 20px;
					font-weight: bold;
				}
				>span:nth-child(3) {
					font-size: 36px;
					font-family: Bebas;
				}
			}
			.bottom-item-bottom {
				margin-top: 4px;
				font-size: 14px;
				color: #333;
			}
		}
		.bottom-item:last-child {
			margin-right: 0;
		}
	}
}

.watermark {
	position: absolute;
	left: 0;
	right: 0;
	top: 45px;
	text-align: center;
	font-size: 70px;
	font-weight: bold;
	color: #EEEEEE;

}
.title {
	position: absolute;
	left: 0;
	right: 0;
	top: 90px;
	font-size: 40px;
	text-align: center;
	color: #333;
}

.official-advantage {
	position: relative;
	height: 716px;
	background-color: #F4F5F7;
	.content {
		position: absolute;
		left: 0;
		right: 0;
		top: 226px;
		width: 1020px;
		margin: 0 auto;
	}
	.mySwiper {
		float: left;
		width: 800px;
	}
	.swiper-slide {
		position: relative;
        width: 310px;
        height: 390px;
		background-position: center;
        background-size: cover;
    }

    .swiper-slide img {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
        display: block;
        width: 200px;
		height: 252px;
		//transform: scale(0.645, 0.795);
		transition: all .3s;
    }
	.swiper-slide span {
		position: absolute;
		left: 20px;
		top: 20px;
		color: #333;
		font-size: 20px;
	}
	.swiper-slide-active img {
		width: 100%;
		height: 100%;
		//transform: scale(1);
	}
	.swiper-slide-watermark {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto;
        display: block;
        width: 200px;
		height: 252px;
		background-color: rgb(255, 255, 255, .5);
		transition: all .3s;
	}
	.swiper-slide-active .swiper-slide-watermark {
		width: 100%;
		height: 100%;
		background-color: rgb(255, 255, 255, 0);
	}

	.control {
		max-width: 176px;
		width: 176px;
		margin-right: 40px;
		padding-top: 62px;
		float: left;
	}

	.control-page {
		>span:nth-child(1) {
			font-size: 60px;
			line-height: 55px;
			font-family: Bebas;
			color: #FF003C;
		}
		>span:nth-child(2) {
			margin-left: 15px;
			font-size: 40px;
			line-height: 40px;
			font-family: Bebas;
			color: #C3C4C6;
		}
	}

	.control-title {
		margin-top: 49px;
		font-size: 32px;
		line-height: 30px;
		color: #333;
	}
	.control-subTitle {
		margin-top: 24px;
		font-size: 16px;
		line-height: 16px;
		color: #666;
	}
	.control-content {
		position: absolute;
		bottom: 62px;
		>div {
			float: left;
			width: 32px;
			height: 32px;
			border-radius: 50%;
			border: 1px solid #707172;
			cursor: pointer;
		}
		>div:hover {
			border-color: #E4E6E8;
			background-color: #E4E6E8;
		}
		.control-prev {
			margin-right: 20px;
		}
	}
}

.official-product {
	position: relative;
	height: 1000px;
	background-color: #ff003c;
	
	.watermark {
		color: rgb(250, 250, 250, .2);
	}
	.title {
		color: #fff;
	}
	.official-product-bg {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		>img {
			display: block;
			width: 100%;
			height: 100%;
			opacity: .4;
		}
	}
	.official-product-tabbar {
		position: absolute;
		top: 186px;
		width: 204px;
		height: 40px;
		left: 0;
		right: 0;
		margin: 0 auto;
		.official-product-tabbar-item {
			float: left;
			width: 72px;
			margin-right: 60px;
			font-size: 18px;
			line-height: 17px;
			text-align: center;
			color: #fff;
			cursor: pointer;
		}
		.official-product-tabbar-item:last-child {
			margin-right: 0;
		}
		.official-product-tabba-slide {
			position: absolute;
			bottom: 5px;
			width: 68px;
			height: 2px;
			background-color: #fff;
			transition: transform .2s;
		}
	}
	.contnent-phone {
		position: absolute;
		width: 967px;
		top: 262px;
		left: 0;
		right: 0;
		margin: 0 auto;
		.phone {
			z-index: 8;
			position: relative;
			float: left;
			width: 292px;
			height: 584px;
			margin-right: 135px;
		}	
		.phone-detail {
			position: absolute;
			width: 271px;
			height: 556px;
			left: 10px;
			top: 14px;
			border-radius: 24px;
			background-color: #000;
			>img {
				position: relative;
				display: block;
				width: 271px;
				height: 556px;
				border-radius: 24px;
			}
		}
		.phone-detail-active {
			z-index: 2;
		}
	}
	.contnent-pc {
		position: absolute;
		width: 1188px;
		top: 262px;
		left: 0;
		right: 0;
		margin: 0 auto;

		.pc {
			float: left;
			width: 638px;
			height: 383px;
			margin-right: 10px;
			margin-top: 100px;
		}
		.pc-detail {
			position: absolute;
			width: 484px;
			height: 309px;
			left: 76px;
			top: 114px;
			>img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.pc-detail-active {
			z-index: 2;
		}
	}
	.product-list {
		float: left;
		padding-top: 71px;
		.product-item {
			position: relative;
			width: 540px;
			height: 120px;
			margin-bottom: 40px;
			// background: url(../../../assets/sys/official/card_unSelect.png);
			// background-repeat: no-repeat;
			// background-size: 100% 100%;
			
			cursor: pointer;
			//background-color: rgb(255, 30, 87);
			>img {
				display: block;
				z-index: 3;
				position: absolute;
				left: 50px;
				width: 40px;
				height: 40px;
				top: 40px;
			}
			.product-title {
				z-index: 3;
				position: absolute;
				left: 139px;
				top: 34px;
				font-size: 20px;
				line-height: 19px;
				color: #fff;
			}
			.product-subTitle {
				z-index: 3;
				position: absolute;
				left: 139px;
				bottom: 34px;
				font-size: 16px;
				line-height: 16px;
				color: #fff;
			}
			.product-bg {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				>img {
					width: 100%;
					height: 100%;
				}
				opacity: .5;
			}
			.product-bg-active {
				z-index: 2;
				opacity: .2;
			}
		}
		.product-item:last-child {
			margin-bottom: 0;
		}
		.product-item-active {
			// background: url(../../../assets/sys/official/card_select.png);
			// background-repeat: no-repeat;
			// background-size: 100% 100%;
			//background-color: #FD507A;
		}
		// .product-item:hover {
		// 	background-color: #FD507A;
		// }
	}
	.official-product-viewDetail {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 79px;
		width: 160px;
		height: 48px;
		margin: 0 auto;
		border-radius: 24px;
		border: 1px solid #fff;
		font-size: 16px;
		line-height: 46px;
		text-align: center;
		color: #fff;
		cursor: pointer;
		user-select: none;
	}
}

.official-marke {
	position: relative;
	height: 692px;
	background-image: url(../../../assets/sys/official/yynl_bg.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	.official-marke-list {
		overflow: hidden;
		position: absolute;
		left: 0;
		right: 0;
		top: 187px;
		width: 1200px;
		margin: 0 auto;
		.official-marke-item {
			position: relative;
			float: left;
			width: 228px;
			height: 405px;
			margin-right: 15px;
			cursor: pointer;
			.official-marke-item-content {
				z-index: 3;
				position: absolute;
				top: 234px;
				left: 20px;
				transform: translateY(0);
				transition: all .3s;
				.official-marke-item-serialImage {
					width: 51px;
					height: 60px;
				}
				.official-marke-item-title {
					margin: 26px 0 16px;
					font-size: 24px;
					line-height: 23px;
					color: #fff;
				}
				.official-marke-item-subTitle {
					font-size: 16px;
					line-height: 15px;
					color: #fff;
				}
			}
			.official-marke-item-overlay {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-image: url(../../../assets/sys/official/marke-unActive.png);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				transition: all .3s;
			}
			.official-marke-item-img {
				display: block;
				width: 100%;
				height: 100%;
			}
		}
		.official-marke-item:last-child {
			margin-right: 0;
		}
		.official-marke-item:hover {
			.official-marke-item-overlay {
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-image: url(../../../assets/sys/official/marke-active.png);
			}
			
			.official-marke-item-content {
				transform: translateY(-20px);
			}
		}
	}
}

.official-case {
	position: relative;
	height: 852px;
	background-color: #F4F5F7;
	.official-case-content {
		position: absolute;
		left: 0;
		right: 0;
		top: 186px;
		width: 1200px;
		height: 566px;
		margin: 0 auto;
		background-color: #fff;
		.case-swiper {
			width: 100%;
			height: 100%;
		}
		.case-swiper-item {
			min-width: 1200px;
			width: 1200px;
			height: 566px;
			.case-swiper-item-img {
				width: 318px;
				height: 566px;
				float: left;
			}
			.case-swiper-item-content {
				position: relative;
				float: left;
				width: 882px;
				height: 566px;
				padding: 80px 72px 0 60px;
				
				.case-swiper-item-title {
					position: relative;
					display: inline;
					>span {
						font-size: 32px;
						line-height: 30px;
					}
					.case-swiper-item-tip {
						position: absolute;
						right: -80px;
						top: -4px;
						width: 64px;
						height: 24px;
						font-size: 14px;
						line-height: 24px;
						text-align: center;
						color: #FF003C;
						background-color: #FFE6EC;
					}
					.case-swiper-item-slide {
						position: absolute;
						left: 0;
						bottom: -24px;
						width: 40px;
						height: 4px;
						background-color: #FF003C;
					}
				}
				.case-swiper-item-subTitle {
					margin-top: 45px;
					font-size: 16px;
					color: #666;
					line-height: 22px;
				}
				.case-swiper-item-detail {
					margin-top: 100px;
					.case-swiper-item-play {
						float: left;
						margin-right: 79px;
						.case-swiper-item-play-data {
							height: 33px;
							>span:nth-child(1) {
								font-family: Bebas;
								font-size: 36px;
								line-height: 33px;
								color: #FF003C;
							}
							>span:nth-child(2) {
								font-size: 20px;
								line-height: 33px;
								color: #FF003C;
							}
							>span:nth-child(3) {
								font-family: Bebas;
								font-size: 36px;
								line-height: 33px;
								color: #FF003C;
							}
						}
						>div:nth-child(2) {
							margin-top: 17px;
							font-size: 14px;
							line-height: 14px;
							color: #333;
						}
					}
					.case-swiper-item-num {
						float: left;
						margin-right: 98px;
						>div:nth-child(1) {
							font-size: 36px;
							line-height: 33px;
							font-family: Bebas;
							color: #FF003C;
						}

						>div:nth-child(2) {
							margin-top: 17px;
							font-size: 14px;
							line-height: 14px;
							color: #333;
						}
					}
				}
				
			}
		}
		.official-case-prev {
			position: absolute;
			width: 30px;
			height: 30px;
			left: -60px;
			top: 0;
			bottom: 0;
			margin: auto 0;
			border-radius: 50%;
			border: 1px solid #707172;
			cursor: pointer;
		}
		.official-case-next {
			position: absolute;
			width: 30px;
			height: 30px;
			right: -60px;
			top: 0;
			bottom: 0;
			margin: auto 0;
			border-radius: 50%;
			border: 1px solid #707172;
			cursor: pointer;
		}
		.official-case-prev:hover, .official-case-next:hover {
			border-color: #E4E6E8;
			background-color: #E4E6E8;
		}
	}
}

.official-cooperation {
	position: relative;
	height: 634px;
	background: url(../../../assets/sys/official/cooperation_bg.png);
	background-repeat: no-repeat;
	background-size: 100% 100%;
	.watermark {
		color: rgb(250, 250, 250, .2);
	}
	.title {
		color: #fff;
	}
	.official-cooperation-content {
		width: 1100px;
		position: absolute;
		left: 0;
		right: 0;
		top: 186px;
		margin: 0 auto;
		.ant-form-item-control-wrapper {
			width: 100%;
			.ant-input, .ant-cascader-picker, .ant-cascader-input, .ant-select-selection {
				color: #fff;
				border-color: #F8A3AD;
				background-color: transparent;
				svg {
					color: #fff;
				}
				.ant-cascader-picker-clear {
					background-color: transparent;
				}
			}
			.ant-input:focus, .ant-cascader-picker:focus .ant-cascader-input, .ant-select-selection:focus {
				border-color: #fff;
				box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
			}
			.ant-input::-webkit-input-placeholder, .ant-select-selection__placeholder {
				color: #F8A3AD;
			}
		}
	}
	.official-cooperation-form-error {
		position: absolute;
		left: 0;
		bottom: -38px;
		font-size: 14px;
		color: #fff;
	}
	.official-cooperation-submit {
		display: block;
		width: 160px;
		height: 48px;
		margin: 0 auto;
		font-size: 16px;
		text-align: center;
		line-height: 48px;
		border-radius: 24px;
		border: none;
		color: #FF003C;
		background-color: #fff;
		outline: none;
		cursor: pointer;
	}
}

.official-footer {
	position: relative;
	height: 167px;
	background-color: #333;
	.official-footer-content {
		position: relative;
		width: 62.5%;
		height: 100%;
		margin-left: 18.75%;
	}
	.official-footer-email {
		position: absolute;
		left: 0;
		top: 40px;
		color: #C8C8C8;
		font-size: 16px;
		line-height: 18px;
	}
	.official-footer-address {
		position: absolute;
		top: 86px;
		left: 0;
		font-size: 13px;
		color: #888888;
	}
	.official-footer-company {
		position: absolute;
		bottom: 38px;
		left: 0;
		font-size: 13px;
		color: #888888;
	}
	.official-footer-logo {
		position: absolute;
		right: 0;
		width: 120px;
		height: 40px;
		top: 41px;
	}
}
